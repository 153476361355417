import React from "react"
import { ThemeProvider } from "theme-ui"

import { InterfaceProvider } from "./src/context/interface"
import Theme from "./src/theme"

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={Theme}>
      <InterfaceProvider>{element}</InterfaceProvider>
    </ThemeProvider>
  )
}
