// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-case-js": () => import("./../../../src/templates/case.js" /* webpackChunkName: "component---src-templates-case-js" */),
  "component---src-templates-featured-cases-js": () => import("./../../../src/templates/featured-cases.js" /* webpackChunkName: "component---src-templates-featured-cases-js" */),
  "component---src-templates-featured-design-case-js": () => import("./../../../src/templates/featured-design-case.js" /* webpackChunkName: "component---src-templates-featured-design-case-js" */),
  "component---src-templates-featured-still-case-js": () => import("./../../../src/templates/featured-still-case.js" /* webpackChunkName: "component---src-templates-featured-still-case-js" */),
  "component---src-templates-featured-video-case-js": () => import("./../../../src/templates/featured-video-case.js" /* webpackChunkName: "component---src-templates-featured-video-case-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-performance-js": () => import("./../../../src/templates/performance.js" /* webpackChunkName: "component---src-templates-performance-js" */),
  "component---src-templates-program-js": () => import("./../../../src/templates/program.js" /* webpackChunkName: "component---src-templates-program-js" */)
}

