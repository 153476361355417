const breakpoints = ["52em", "64em", "72em"] // ["52em", "52em", "64em"]

export default {
  breakpoints,
  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints[0]})`,
    medium: `@media screen and (min-width: ${breakpoints[1]})`,
    large: `@media screen and (min-width: ${breakpoints[2]})`,
  },
  config: {
    initialColorModeName: "morning",
    useLocalStorage: false
  },
  fontSizes: [6, 8, 12, 16, 20, 32],
  colors: {
    highlight: 'transparent',
    white: "#fff",
    background: "#f2f2f2",
    darkest: "#212121",
    gray: "#A5ABBD",
    "performance-light": "#cacbcc",
    "performance-grey": "#D0D2D3",
    "performance-darkgrey": "#525252",
    "performance-blue": "#005DEA",
    "performance-dark": "#000",
    "performance-lightblue": "#005EEA",
    modes: {
      midnight: {
        white: "#111A23",
        background: "#101820",
        darkest: "#fff",
        gray: "#A5ABBD",
      },
    },
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "Formular, sans-serif",
    heading: "pragmatica-extended, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    light: 200,
    body: 300,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
  variants: {
    creativeSessionInput: {
      border: "1px solid black",
      height: "50px",
      paddingLeft: 3,
      backgroundColor: "transparent",
      borderBottom: ["none", "1px solid black"],
      borderRight: ["1px solid black", "1px solid black", "1px solid black", "none"],
    },
    navLink: {
      cursor: "pointer",
      fontFamily: "heading",
      marginLeft: 0,
      listStyle: "none",
      textTransform: "uppercase",
      a: {
        textDecoration: "none",
      },
    },
  },
  text: {},
  buttons: {
    primary: {
      color: "white",
      bg: "primary",
    },
  },
}
